import React, { useEffect, useState } from "react";
import { FormContext, useForm } from "react-hook-form";
import { useTranslation, Trans } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Form, InputGroup, InputHidden } from "components/core/Form";
import FormCEPObserver from "components/core/Form/FormCEPObserver";
import { Columns, Container, FlexColumn } from "components/core/Grid";
import InputForm from "components/core/InputForm";
import { InputWithTextArea } from "components/core/InputForm/styled";
import Checkbox from "components/core/Checkbox";
import { ErrorMsg, Field, InputWrapper, Label } from "components/core/InputForm/styled";
import SelectForm, { SelectDotWithContext } from "components/core/SelectForm";
import { Subtitle, Span } from "components/core/Typography";
import { mapToSelectOptions } from "helpers";
import { EMITTER_COLORS_DICTIONARY } from "utils/constants";
import { MASK_TYPES } from "utils/masks";
import { getStates } from "utils/mocks/brazilCitiesStates";
import { validationSchema } from "utils/schemas/emitter";
import { destructionEmitterFromAPI, getOnlyIDs } from "utils/transform/emitter";
import * as S from "./styled";

const EmitterEdit = ({
  onSubmit,
  emitterDetails,
  submitRef,
  activities,
  statusList,
  subStatusList,
  AuthenticationTypes,
  setShowActivitiesModal,
  regimes,
  setShowInvoiceTypes,
  emitterToUpdate,
  handleCpf,
  onInvoiceTypesChange,
  cpfRejected,
  setCommentsToUpdate,
  ResponsiblesForSale,
  Sources,
  ActivityGroups,
  PartnersObvia,
  FinancialStatus
}) => {
  const states = getStates();
  const { t: translate } = useTranslation();
  const { multiSelectItems } = useSelector(({ layout }) => layout);
  const { isLoading: isLoadingCEP } = useSelector(({ address }) => address);
  const defaultValues = emitterToUpdate || destructionEmitterFromAPI(emitterDetails);
  const [defaultInvoiceTypes, setDefaultInvoiceTypes] = useState([]);
  const [showNationalMEIAuth, setShowNationalMEIAuth] = useState(false);
  const [InvoicePermission, setInvoicePermission] = useState(defaultValues.issueInvoicePermission);
  const [countSecondaryActivities, setCountSecondaryActivities] = useState(
    JSON.parse(defaultValues?.secondaryActivities?.length).length ?? 0,
  );
  const [newZipCode, setNewZipCode] = useState("");
  const [comments, setComments] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [subStatusOptions, setSubStatusOptions] = useState([]);
  const { register, handleSubmit, errors, watch, setValue, getValues } = useForm({
    validationSchema,
    defaultValues: {
      ...defaultValues,
      issAliquot: defaultValues.issAliquot?.toString().replace(".", ","),
    },
    mode: "onBlur",
    submitFocusError: true,
  });

  const watchZipCode = watch("zipCode");
  const SP = getValues("state");
  const idAuthenticationType = watch("idAuthenticationType");
  const watchIsNationalMEI = watch("isNationalMEI");
  const watchEnterpriseStatusId = watch("idEnterpriseStatus");

  useEffect(() => {
    onInvoiceTypesChange(idAuthenticationType);
  }, [idAuthenticationType, onInvoiceTypesChange]);

  useEffect(() => {
    setSubStatusOptions([]);
    const subStatus = subStatusList.filter(
      (item) => item.enterpriseStatusId === Number(watchEnterpriseStatusId),
    );
    if (subStatus.length) {
      setSubStatusOptions(subStatus);
    } else {
      setSubStatusOptions([]);
    }
  }, [watchEnterpriseStatusId, subStatusList, setSubStatusOptions, setValue]);

  useEffect(() => {
    setShowNationalMEIAuth(
      [true, "true"].includes(watchIsNationalMEI) && emitterDetails.idAuthenticationType === 2,
    );
  }, [watchIsNationalMEI]);

  useEffect(() => {
    const { secondaryActivities } = multiSelectItems;
    if (secondaryActivities.length > 0) {
      const newSecondaryActivities = getOnlyIDs(secondaryActivities);
      setValue("secondaryActivities", JSON.stringify(newSecondaryActivities), true);
      setCountSecondaryActivities(newSecondaryActivities.length);
    } else if (secondaryActivities.length === 0) {
      setCountSecondaryActivities(emitterDetails.secondaryActivities.length);
    }
  }, [multiSelectItems, setValue, emitterDetails.secondaryActivities.length]);

  useEffect(() => {
    const { invoiceTypes } = multiSelectItems;
    if (invoiceTypes.length > 0) {
      const newInvoiceTypes = getOnlyIDs(invoiceTypes);
      setValue("invoiceTypes", JSON.stringify(newInvoiceTypes), true);
    }
  }, [multiSelectItems, setValue]);

  useEffect(() => {
    if (watchZipCode.length === 10) {
      setNewZipCode(watchZipCode);
    }
  }, [watchZipCode]);

  useEffect(() => {
    setValue("specialTaxationRegime", regimes?.specialTaxationRegime);
  }, [regimes?.specialTaxationRegime, setValue]);

  useEffect(() => {
    const oldInvoiceTypes = defaultValues?.enterpriseInvoiceType?.map(({ invoiceType }) => ({
      ...invoiceType,
    }));
    const newInvoiceTypes = oldInvoiceTypes?.map(({ name }) => ({
      name,
    }));
    setDefaultInvoiceTypes(newInvoiceTypes);
  }, [defaultValues.enterpriseInvoiceType]);

  useEffect(() => {
    setValue("issueInvoicePermission", InvoicePermission);
  }, [setValue, InvoicePermission]);

  useEffect(() => {
    if (comments && comments != "") {
      defaultValues.comments = comments;
    }
  }, [defaultValues]);

  useEffect(() => {
    setCommentsToUpdate(comments);
  }, [comments]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const errorCpf = () => {
    if (!cpfRejected) return errors.cpf?.message;
    if (cpfRejected) return <Trans i18nKey="required.cpfNotValid" />;
  };

  return (
    <Container maxWidth="md">
      <FormContext watch={watch} setValue={setValue}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FlexColumn horizontalAlign="flex-start">
            <InputGroup marginBottom="3rem">
              <Span>Campos Obrigatórios *</Span>
            </InputGroup>

            <InputGroup>
              <SelectDotWithContext
                register={register}
                id="idEnterpriseStatus"
                name="idEnterpriseStatus"
                label={`${translate("status")} *`}
                options={mapToSelectOptions(statusList, "id", "name", EMITTER_COLORS_DICTIONARY)}
              />
              <SelectDotWithContext
                register={register}
                id="enterpriseSubStatusId"
                name="enterpriseSubStatusId"
                label={`${translate("Sub-status")}`}
                options={mapToSelectOptions(
                  subStatusOptions,
                  "id",
                  "name",
                  EMITTER_COLORS_DICTIONARY,
                )}
              />
              <Label htmlFor="comments">{translate("comments")}</Label>
              <InputWithTextArea
                register={register}
                type="text"
                name="comments"
                style={{ resize: "both" }}
                defaultValue={emitterDetails.comments}
                onChange={(e) => setComments(e.target.value)}
              />
            </InputGroup>
            <Subtitle>{translate("contactInformation")}</Subtitle>

            <InputGroup>
              <Checkbox
                name="invoicePermission"
                id="invoicePermission"
                label={translate("issueInvoicePermission")}
                handleChange={() => setInvoicePermission(!InvoicePermission)}
                checked={InvoicePermission}
              />
              <InputHidden ref={register} name="issueInvoicePermission" />
              <InputForm
                register={register}
                type="text"
                name="name"
                label={`${translate("name")} *`}
                errorMsg={errors?.name?.message}
              />
              <InputForm
                register={register}
                type="email"
                name="email"
                label={`${translate("email")} *`}
                errorMsg={errors.email?.message}
              />
              <InputForm
                register={register}
                type="email"
                name="billingEmail"
                label={`${translate("billingEmail")}`}
                errorMsg={errors.billingEmail?.message}
              />
              <InputGroup columns={2} paddingLeft={0}>
                <InputForm
                  register={register}
                  type="text"
                  name="phoneNumber"
                  label={`${translate("phone")} *`}
                  errorMsg={errors.phoneNumber?.message}
                  mask={MASK_TYPES.phone}
                />
                <InputForm
                  register={register}
                  type="text"
                  name="billingPhone"
                  label={`${translate("billingPhone")}`}
                  errorMsg={errors.billingPhone?.message}
                  mask={MASK_TYPES.phone}
                />
              </InputGroup>
              <InputForm
                register={register}
                type="text"
                name="cpf"
                label={`${translate("cpf")} *`}
                errorMsg={errorCpf()}
                mask={MASK_TYPES.cpf}
                onBlur={handleCpf}
              />
              <InputForm
                register={register}
                type="text"
                name="dateOfBirth"
                label={`${translate("dateOfBirth")}`}
                mask={MASK_TYPES.date}
                errorMsg={errors.dateOfBirth?.messge}
              />
            </InputGroup>
            <Subtitle>{translate("companyInformation")}</Subtitle>
            <InputGroup>
              <InputForm
                register={register}
                type="text"
                name="cnpj"
                label={`${translate("cnpj")} * (Obrigatório quando a situação for "Ativo")`}
                placeholder={translate("typeCNPJ")}
                errorMsg={errors.cnpj?.message}
                mask={MASK_TYPES.cnpj}
              />
              <InputForm
                register={register}
                type="text"
                name="enterpriseName"
                label={`${translate(
                  "enterpriseName",
                )} * (Obrigatório quando a situação for "Ativo")`}
                placeholder="Digite a razão social"
                errorMsg={errors.enterpriseName?.message}
              />
              <InputForm
                register={register}
                type="text"
                name="fantasyName"
                label={`${translate("fantasyName")}`}
                errorMsg={errors.fantasyName?.message}
                placeholder={translate("typeFantasyName")}
              />
              <InputForm
                register={register}
                type="text"
                name="stateRegistration"
                label={`${translate("stateRegistration")} ${SP === "SP" ? "" : ""}`}
                placeholder={translate("typeStateRegistration")}
                errorMsg={errors.stateRegistration?.message}
              />
              <InputForm
                register={register}
                type="text"
                name="cityRegistration"
                label={`${translate("cityRegistration")} ${
                  SP === "SP"
                    ? ""
                    : "* (caso não tenha INSCRIÇÃO ESTADUAL este campo se torna obrigatório)"
                } `}
                placeholder={translate("typeCityRegistration")}
                errorMsg={errors.cityRegistration?.message}
              />

              <SelectForm
                register={register}
                name="idEnterpriseActivity"
                label={`${translate(
                  "primaryActivity",
                )} * (Obrigatório quando a situação for "Ativo")`}
                options={mapToSelectOptions(activities, "id", "name")}
                errorMsg={errors.idEnterpriseActivity?.message}
              />
              <InputHidden ref={register} name="secondaryActivities" />
              <InputWrapper>
                <Label>{translate("secondaryActivity")}</Label>
                <Field type="button" as="button" onClick={() => setShowActivitiesModal(true)}>
                  {countSecondaryActivities
                    ? translate("selectedActivities", { count: countSecondaryActivities })
                    : translate("select")}
                </Field>
                <ErrorMsg>{errors.secondaryActivities?.message}</ErrorMsg>
              </InputWrapper>
              {regimes?.specialTaxationRegime && (
                <SelectForm
                  register={register}
                  name="idSpecialTaxRegime"
                  label={`${translate("specialTaxRegime")} *`}
                  options={mapToSelectOptions(regimes.items, "code", "name")}
                  errorMsg={errors.idSpecialTaxRegime?.message}
                />
              )}
              <SelectForm
                register={register}
                name="culturalPromoter"
                label={`${translate("culturalPromoter")} *`}
                options={[
                  { text: "Sim", value: true },
                  { text: "Não", value: false },
                ]}
                errorMsg={errors.culturalPromoter?.message}
              />
              <InputGroup columns={2} paddingLeft={0}>
                <SelectForm
                  register={register}
                  name="idAuthenticationType"
                  label={translate("AuthenticationType")}
                  options={mapToSelectOptions(AuthenticationTypes, "id", "name")}
                  errorMsg={errors.idAuthenticationType?.message}
                />
                <InputForm
                  register={register}
                  type="text"
                  name="issAliquot"
                  label={emitterDetails?.type?.id === 2 ? "ISS *" : "ISS"}
                  errorMsg={errors.issAliquot?.message}
                  content="%"
                  mask={MASK_TYPES.numberWithComma}
                />
              </InputGroup>
              <InputHidden ref={register} name="invoiceTypes" />
              <InputWrapper>
                <Label>{`${translate("invoiceType")} *`}</Label>
                <Field type="button" as="button" onClick={() => setShowInvoiceTypes(true)}>
                  {multiSelectItems?.invoiceTypes.length > 0
                    ? `${multiSelectItems?.invoiceTypes?.map(({ name }) => name)} ${translate(
                        "selected_female",
                      )}`
                    : `${defaultInvoiceTypes?.map(({ name }) => name)} ${translate(
                        "selected_female",
                      )}`}
                </Field>
                <ErrorMsg>{errors.invoiceTypes?.message}</ErrorMsg>
              </InputWrapper>
              {emitterDetails?.type?.id === 2 && (
                <SelectForm
                  register={register}
                  name="useNationalPortal"
                  label={`${translate("Emitir Nota Fiscal pelo Portal Nacional")} *`}
                  options={[
                    { text: "Sim", value: true },
                    { text: "Não", value: false },
                  ]}
                />
              )}
              <InputForm
                register={register}
                type="text"
                name="operationsNature"
                label={`${translate("operationsNature")}`}
                errorMsg={errors.operationsNature?.message}
                placeholder={translate("typeOperationsNature")}
              />
              <InputGroup columns={2} paddingLeft={0}>
                <SelectForm
                  register={register}
                  name="constitution"
                  label={`${translate("constitution")}`}
                  options={[
                    { text: "Sim", value: true },
                    { text: "Não", value: false },
                  ]}
                  errorMsg={errors.constitution?.message}
                />
                <SelectForm
                  register={register}
                  name="priority"
                  label={`${translate("priority")}`}
                  options={[
                    { text: "Alta", value: "Alta" },
                    { text: "Média", value: "Média" },
                    { text: "Baixa", value: "Baixa" },
                    { text: "Nenhuma", value: "Nenhuma" },
                  ]}
                  errorMsg={errors.priority?.message}
                />
              </InputGroup>
              <SelectForm
                register={register}
                name="responsibleSale"
                label={`${translate("responsibleForSale")}`}
                options={mapToSelectOptions(ResponsiblesForSale, "id", "name")}
                errorMsg={errors.responsibleSale?.message}
              />
              <InputGroup columns={2} paddingLeft={0}>
                <SelectForm
                  register={register}
                  name="source"
                  label={`${translate("source")}`}
                  options={mapToSelectOptions(Sources, "id", "name")}
                  errorMsg={errors.source?.message}
                />
                <SelectForm
                  register={register}
                  name="partnersObvia"
                  label={`${translate("partnersObvia")} *`}
                  options={mapToSelectOptions(PartnersObvia, "id", "name")}
                  errorMsg={errors.partnersObvia?.message}
                />
              </InputGroup>
              <SelectForm
                register={register}
                name="activityGroup"
                label={`${translate("activityGroup")}`}
                options={mapToSelectOptions(ActivityGroups, "id", "name")}
                errorMsg={errors.activityGroup?.message}
              />
              <SelectForm
                register={register}
                name="financialStatus"
                label={`${translate("financialStatus")}`}
                options={mapToSelectOptions(FinancialStatus, "id", "name")}
                errorMsg={errors.FinancialStatus?.message}
              />
              {emitterDetails?.type?.id === 1 && (
                <>
                  <Columns columns={showNationalMEIAuth || true ? 3 : 1}>
                    <FlexColumn style={{ marginBottom: "0" }}>
                      <SelectForm
                        register={register}
                        name="isNationalMEI"
                        label={`${translate("National MEI")} *`}
                        options={[
                          { text: "Sim", value: true },
                          { text: "Não", value: false },
                        ]}
                        marginBottom="0"
                      />
                    </FlexColumn>
                    {(showNationalMEIAuth || true) && (
                      <>
                        <FlexColumn
                          style={{ paddingLeft: "1rem", paddingRight: "1rem", marginBottom: "0" }}
                        >
                          <InputForm
                            register={register}
                            type="text"
                            name="nationalMEIUser"
                            label={`${translate("user")} *`}
                            errorMsg={errors.nationalMEIUser?.message ? "" : null}
                            marginBottom="0"
                          />
                        </FlexColumn>
                        <FlexColumn style={{ marginBottom: "0" }}>
                          <InputForm
                            register={register}
                            name="nationalMEIPassword"
                            type={showPassword ? "text" : "password"}
                            label={`${translate("password")} *`}
                            autoComplete="new-password"
                            marginBottom="0"
                          >
                            {showPassword ? (
                              <S.IconPasswordShow onClick={handleClickShowPassword} />
                            ) : (
                              <S.IconPasswordHide onClick={handleClickShowPassword} />
                            )}
                          </InputForm>
                        </FlexColumn>
                      </>
                    )}
                  </Columns>
                  {(errors?.isNationalMEI?.message ||
                    errors?.nationalMEIPassword?.message ||
                    errors?.nationalMEIUser?.message) && (
                    <Columns columns={1} style={{ margin: "0" }}>
                      {errors.isNationalMEI?.message && (
                        <ErrorMsg>{errors.isNationalMEI?.message}</ErrorMsg>
                      )}
                      {errors.nationalMEIUser?.message && (
                        <ErrorMsg>{errors.nationalMEIUser?.message}</ErrorMsg>
                      )}
                      {errors.nationalMEIPassword?.message && (
                        <ErrorMsg>{errors.nationalMEIPassword?.message}</ErrorMsg>
                      )}
                    </Columns>
                  )}
                </>
              )}
            </InputGroup>
            <Subtitle style={{ marginTop: "2.4rem" }}>{translate("addressInformation")}</Subtitle>
            <InputGroup>
              <InputGroup columns={2} paddingLeft={0}>
                <InputForm
                  register={register}
                  type="text"
                  name="zipCode"
                  label={`${translate("zipCode")} *`}
                  placeholder={translate("typeZipCode")}
                  errorMsg={errors.zipCode?.message}
                  mask={MASK_TYPES.cep}
                  isLoading={isLoadingCEP}
                />
                <SelectForm
                  register={register}
                  name="state"
                  label={`${translate("state")} *`}
                  disabled
                  options={mapToSelectOptions(states)}
                  errorMsg={errors.state?.message}
                />
              </InputGroup>
              <InputForm
                register={register}
                name="city"
                label={`${translate("city")} *`}
                disabled
                errorMsg={errors.city?.message}
              />
              <InputForm
                register={register}
                type="text"
                name="neighborhood"
                label={`${translate("neighborhood")} *`}
                placeholder={translate("typeNeighborhood")}
                errorMsg={errors.neighborhood?.message}
              />
              <InputForm
                register={register}
                type="text"
                name="street"
                label={`${translate("street")} *`}
                placeholder={translate("typeStreet")}
                errorMsg={errors.street?.message}
              />
              <InputGroup columns={2} paddingLeft={0}>
                <InputForm
                  register={register}
                  type="text"
                  name="number"
                  label={`${translate("number")} *`}
                  placeholder={translate("typeNumber")}
                  errorMsg={errors.number?.message}
                />
                <InputForm
                  register={register}
                  type="text"
                  name="complement"
                  label={translate("complement")}
                  placeholder={translate("typeComplement")}
                  errorMsg={errors.complement?.message}
                />
              </InputGroup>
            </InputGroup>
            <InputHidden ref={register} type="text" name="specialTaxationRegime" />
            <InputHidden ref={submitRef} type="submit" value="Submit" />
          </FlexColumn>
        </Form>
        <FormCEPObserver zipCode={defaultValues.zipCode} newZipCode={newZipCode} />
      </FormContext>
    </Container>
  );
};

EmitterEdit.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onInvoiceTypesChange: PropTypes.func.isRequired,
  emitterDetails: PropTypes.object.isRequired,
  submitRef: PropTypes.object.isRequired,
  activities: PropTypes.array.isRequired,
  statusList: PropTypes.array.isRequired,
  AuthenticationTypes: PropTypes.array.isRequired,
  setShowActivitiesModal: PropTypes.func.isRequired,
  regimes: PropTypes.object.isRequired,
  setShowInvoiceTypes: PropTypes.func.isRequired,
  emitterToUpdate: PropTypes.object,
  handleCpf: PropTypes.func.isRequired,
  cpfRejected: PropTypes.bool.isRequired,
};

EmitterEdit.defaultProps = {
  emitterToUpdate: null,
};

export default EmitterEdit;
