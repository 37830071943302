import * as Yup from "yup";
import { transformEmptyToUndefined } from "helpers/sanitize";

const validationSchema = Yup.object().shape({
  clientNameOrCNPJ: Yup.string(),
  dateBegin: Yup.string()
    .transform(transformEmptyToUndefined)
    .min(10, "A data é muito curta")
    .notRequired(),
  dateEnd: Yup.string()
    .transform(transformEmptyToUndefined)
    .when("dateBegin", {
      is: (value) => !!value,
      then: Yup.string().required("Digite uma data final"),
    })
    .min(10, "A data é muito curta"),
});

const defaultValues = {
  clientNameOrCNPJ: "",
  dateBegin: "",
  dateEnd: "",
};

export { validationSchema, defaultValues };
