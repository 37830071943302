import React, { useEffect } from "react";
import { Modal } from "components/core/Modal";
import { billingReportSchema } from "utils/schemas/billingReport";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { MASK_TYPES } from "utils/masks";
import { LayoutActions } from "store/ducks/layout";
import { Span } from "components/core";
import { useDispatch, useSelector } from "react-redux";
import { FinancialActions } from "store/ducks/financial";
import { mapToSelectOptions } from "helpers";
import { ParamsActions } from "store/ducks/params";
import { useRequest } from "hooks";
import { Flex } from "../Grid";
import { Form, InputGroup } from "../Form";
import InputForm from "../InputForm";
import { Label } from "../InputForm/styled";
import { ModalActions } from "../Modal/styled";
import Button from "../Button";
import SelectForm from "../SelectForm";

const BillingModal = ({ validationSchema, handleNewSubmit, handleCancelClick }) => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm({
    validationSchema,
    mode: "onBlur",
    submitFocusError: true,
    nativeValidation: false,
  });

  const { ParamsRequests, certificatesOpt } = useSelector(({ params }) => params);
  const { requestStatus } = useSelector(({ financial }) => financial);

  useEffect(() => {
    dispatch(ParamsActions.getCertificates());
  }, []);

  return (
    <Flex verticalAlign="center">
      <Form onSubmit={handleSubmit(handleNewSubmit)}>
        <Label>Período:</Label>
        <InputGroup paddingLeft="0" columns="2">
          <InputForm
            register={register}
            type="text"
            name="dateBegin"
            placeholder="MM/YYYY"
            label="De:"
            direction="row"
            horizontalAlign="center"
            verticalAlign="center"
            labelDark
            isLoading={requestStatus.GET_REPORT_BILLING === "pending"}
            errorMsg={errors.dateBegin?.message}
            mask={MASK_TYPES.dateMonth}
          />
          <InputForm
            register={register}
            type="text"
            name="dateEnd"
            label="Até:"
            placeholder="DD/MM/YYYY"
            direction="row"
            horizontalAlign="center"
            verticalAlign="center"
            labelDark
            isLoading={requestStatus.GET_REPORT_BILLING === "pending"}
            errorMsg={errors.dateEnd?.message}
            mask={MASK_TYPES.date}
          />
        </InputGroup>
        <SelectForm
          register={register}
          name="idCertificate"
          isLoading={ParamsRequests.GET_CERTIFICATES === "pending"}
          label="Certificado para Assinatura"
          options={mapToSelectOptions(certificatesOpt, "id", "nameAccountant")}
          errorMsg={errors.idCertificate?.message}
        />
        <ModalActions>
          <Button variant="outline" handleClick={handleCancelClick}>
            Cancelar
          </Button>
          <Button type="submit">Gerar Relatório</Button>
        </ModalActions>
      </Form>
    </Flex>
  );
};

BillingModal.propTypes = {
  validationSchema: PropTypes.object.isRequired,
  handleNewSubmit: PropTypes.func.isRequired,
  handleCancelClick: PropTypes.func.isRequired,
};

const ReportBillingModal = ({ nameEnterprise, data, showModal, setShowModal }) => {
  const dispatch = useDispatch();
  const { requestStatus, reportBilling } = useSelector(({ financial }) => financial);

  const cleanState = () => {
    dispatch(FinancialActions.cleanState());
  };

  const dispatchResult = (text, type) =>
    dispatch(
      LayoutActions.showConfirmationModal({
        content: <Span>{text}</Span>,
        type,
      }),
    );

  useRequest({
    request: requestStatus.GET_REPORT_BILLING,
    onResolved: () => {
      setShowModal(false);
      const linkSource = `data:application/pdf;base64,${reportBilling}`;
      const downloadLink = document.createElement("a");
      const fileName = `Faturamento ${nameEnterprise}`;

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();

      dispatchResult("Relátorio Gerado com sucesso!", "success");

      cleanState();
    },
    onRejected: () => {
      cleanState();
      dispatchResult("Erro ao gerar relatório,por favor tente novamente!", "error");
    },
  });

  const handleSubmit = ({ dateBegin, dateEnd, idCertificate }) => {
    const { id, enterpriseName, number, street, city, cnpj } = data;

    const separateDateBegin = dateBegin.split("/");
    const monthDateBegin = separateDateBegin[0].padStart(2, "0");
    const yearDateBegin = separateDateBegin[1];

    const newDateBegin = `${yearDateBegin}-${monthDateBegin}-01`;

    const separateDateEnd = dateEnd.split("/");

    const monthDateEnd = separateDateEnd[1].padStart(2, "0");

    const yearDateEnd = separateDateEnd[2];
    const dayDateEnd = separateDateEnd[0];

    const newDateEnd = `${yearDateEnd}-${monthDateEnd}-${dayDateEnd}`;

    dispatch(
      FinancialActions.getReportBilling({
        id,
        idCertificate,
        newDateBegin,
        newDateEnd,
        enterpriseName,
        number,
        cnpj,
        street,
        city,
        dateBegin,
        dateEnd,
      }),
    );
  };

  return (
    <Modal
      title={`Relatório Faturamento - ${nameEnterprise}`}
      confirmLabel="Gerar"
      onCloseModal={() => setShowModal(false)}
      showModal={showModal}
      textAlign="text-left"
      showActions={false}
      width="35%"
    >
      <BillingModal
        handleNewSubmit={handleSubmit}
        validationSchema={billingReportSchema}
        setShowModal={showModal}
        handleCancelClick={() => setShowModal(false)}
      />
    </Modal>
  );
};

export default ReportBillingModal;
