import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { useRequest, useItems, useParams } from "hooks";
import { BatchesActions } from "store/ducks/batches";
import { InvoiceActions } from "store/ducks/invoice";
import { LayoutActions } from "store/ducks/layout";
import Button from "components/core/Button";
import NavBar from "components/core/NavBar";
import { Span } from "components/core/Typography";
import WithSpinner from "components/core/WithSpinner";
import WithSuspense from "components/core/WithSuspense";
import TableFactory from "components/core/TableWithIcons/TableFactory";

import { parseMoneyToFloat, formatDateToParam, formatDate } from "helpers";
import { LINKS, ROUTES } from "utils/constants";
import {
  REQUEST_REJECTED,
  REQUEST_RESOLVED,
  REQUEST_NOT_STARTED,
  REQUEST_PENDING,
} from "utils/constants/request";

const DeleteEnterpriseConfirm = React.lazy(() =>
  import("components/presentation/Documents/Delete"),
);
const DeleteUnifyEnterpriseConfirm = React.lazy(() =>
  import("components/presentation/Documents/Delete"),
);
const IssueInvoice = React.lazy(() => import("containers/Batches/Confirm/IssueInvoice"));

const TableFactoryWithSpinner = WithSpinner(TableFactory);
const DeleteEnterpriseConfirmWithSuspense = WithSuspense(DeleteEnterpriseConfirm);
const DeleteUnifyEnterpriseConfirmWithSuspense = WithSuspense(DeleteUnifyEnterpriseConfirm);
const IssueInvoiceWithSuspense = WithSuspense(IssueInvoice);

const BatchesListContainer = () => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const pagedEnterprisesArray = (array, pageSize, pageNumber) => {
    return array?.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  };
  const {
    BatchesRequests,
    selectEnterprises: { items, limit, total, totalPages, description },
    selectEnterprises,
  } = useSelector(({ batches }) => batches);

  const { rejectedInvoices, invoiceIssusError, InvoicesRequests } = useSelector(
    ({ invoice }) => invoice,
  );

  const [selectEntreprises, setSelectEntreprises] = useState([]);
  const [newTotal, setNewTotal] = useState(0);
  const [newTotalPages, setNewTotalPages] = useState(0);
  const [changeParams, setChangeParams] = useState({});
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showDeleteUnifyConfirm, setShowDeleteUnifyConfirm] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [requestBatches, setRequestBatches] = useState(REQUEST_NOT_STARTED);
  const [dispatchItems, setDispatchItems] = useState([]);
  const [stageItem, setStageItem] = useState(null);
  const [requestPage, setRequestPage] = useState(REQUEST_NOT_STARTED);
  const [showModal, setShowModal] = useState(false);
  const [porcentInvoices, setPorcentInvoices] = useState(0);
  const [listInvoiceErrors, setListInvoiceErrors] = useState([]);
  const createInvoiceRequest = InvoicesRequests.INVOICE_ISSUE;

  useEffect(() => {
    const enterprisesWithParams = pagedEnterprisesArray(items, limit, changeParams.page || 1);
    setSelectEntreprises(enterprisesWithParams);
    setNewTotal(total);
    setNewTotalPages(totalPages);
    if (items === undefined || items.length === 0) {
      history.push(LINKS.batches);
    }
  }, [selectEnterprises, items, limit, total, totalPages, changeParams.page]);

  useEffect(() => {
    if (requestBatches === REQUEST_NOT_STARTED) {
      setRequestPage(BatchesRequests.CONFIRM_BATCHES);
    } else {
      setRequestPage(requestBatches);
    }
  }, [requestBatches]);

  const startThen = () => {
    setRequestBatches(REQUEST_PENDING);
    setShowModal(true);
  };

  const errorBatchesCreated = () => {
    setShowModal(false);
    dispatch(
      LayoutActions.showConfirmationModal({
        content: (
          <>
            <Span>
              <Trans
                i18nKey="batchesCreatedError"
                values={{ name: translate("batche") }}
                components={{ bold: <strong /> }}
              />
            </Span>
            <Span>
              <Trans i18nKey={invoiceIssusError.message} values={{ name: translate("batche") }} />
            </Span>
          </>
        ),
        type: "error",
      }),
    );
    dispatch(InvoiceActions.cleanState());
    history.push(LINKS.batches);
  };

  const finishThen = () => {
    setRequestBatches(REQUEST_RESOLVED);
    setShowModal(false);
  };

  const ConfirmListError = () => {
    const newEnterprisesArray = listInvoiceErrors.map(({ id }) => ({
      id,
    }));

    dispatch(InvoiceActions.invoiceNewList(newEnterprisesArray));
    dispatch(InvoiceActions.invoiceListErrorClean());

    history.push(LINKS.batches);
  };

  const handleSubmit = () => {
    const uuid = uuidv4();
    const date = new Date();
    const dateToISO = date.toISOString();
    const dateFormated = formatDate(dateToISO, 0, false);
    const dateParams = formatDateToParam(dateFormated, "00:00", false);
    const newEnterprisesArray = items.map(({ id, price, justification, cnpj, enterpriseName }) => ({
      idClient: id,
      idInvoiceType: 1,
      price: parseMoneyToFloat(price),
      dateCompetence: dateParams,
      idUnifiedMunicipalService: 1,
      equipmentValue: 0,
      description: "SERVIÇOS DE APOIO ADMINISTRATIVO - PLANO OBVIA",
      justification,
      batchInvoiceUuid: uuid,
      batchInvoiceDate: dateToISO,
      cnpjClient: cnpj,
      nameClient: enterpriseName,
    }));
    setDispatchItems(newEnterprisesArray);
    setStageItem(0);
  };

  useEffect(() => {
    if (createInvoiceRequest === REQUEST_REJECTED) {
      const addErrorInvoice = {
        cnpj: dispatchItems[stageItem]?.cnpjClient,
        enterpriseName: dispatchItems[stageItem]?.nameClient,
        message: translate(invoiceIssusError.message),
        id: dispatchItems[stageItem]?.idClient,
      };
      if (rejectedInvoices.length === 0) {
        dispatch(InvoiceActions.invoiceListError([addErrorInvoice]));
      } else {
        const newItem = [...rejectedInvoices, addErrorInvoice];
        dispatch(InvoiceActions.invoiceListError(newItem));
      }
    }
    if (createInvoiceRequest === REQUEST_RESOLVED || createInvoiceRequest === REQUEST_REJECTED) {
      setTimeout(() => {
        setStageItem(stageItem + 1);
      }, 1500);
    }
  }, [createInvoiceRequest]);

  useEffect(() => {
    const numberToCalc = stageItem + 1;
    const totalItems = dispatchItems.length;
    const porcent = (stageItem * 100) / totalItems;
    setPorcentInvoices(porcent);
    if (stageItem !== null) {
      if (numberToCalc <= totalItems) {
        setTimeout(() => {
          dispatch(InvoiceActions.invoiceIssue(dispatchItems[stageItem]));
        }, 6000);
      } else {
        setTimeout(() => {
          if (totalItems === rejectedInvoices.length) {
            errorBatchesCreated();
          } else if (rejectedInvoices.length !== 0) {
            setListInvoiceErrors(rejectedInvoices);
          } else {
            finishThen();
          }
        }, 2000);
      }
    }
  }, [dispatchItems, stageItem]);

  const handleClick = () => {
    startThen();
    handleSubmit();
  };

  const onResolved = () => {
    dispatch(
      LayoutActions.showConfirmationModal({
        content: (
          <Span>
            <Trans
              i18nKey="batchesCreated"
              values={{ name: translate("batche") }}
              components={{ bold: <strong /> }}
            />
          </Span>
        ),
        type: "success",
      }),
    );
    dispatch(InvoiceActions.cleanState());

    history.push(LINKS.batches);
  };

  useRequest({
    request: requestBatches,
    onResolved,
  });

  const fetchBatches = (newParams) => {
    setChangeParams(newParams);
  };

  const removeItemAll = (arr, value) => {
    let i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  };

  const enterpriseDelete = () => {
    const selectItems = selectedItems?.map((item) => item);
    const newitems = selectItems.map((item) => {
      return removeItemAll(items, item);
    });
    const newSelectEnterprises = {
      items: newitems[0],
      limit: 10,
      total: newitems[0].length,
      totalPages: Math.ceil(newitems[0].length / 10),
      description,
    };
    dispatch(BatchesActions.confirmBatches(newSelectEnterprises));
    setShowDeleteConfirm(false);
    setPage(1);
  };

  const enterpriseUnifyDelete = () => {
    const itemSelectedIndex = items.findIndex(
      (selectedItem) => selectedItem.id === Number(itemToDelete),
    );
    const deletedItem =
      itemSelectedIndex === -1
        ? [...items]
        : items.filter((_, index) => itemSelectedIndex !== index);

    const newItems = [deletedItem];
    const newSelectEnterprises = {
      items: newItems[0],
      limit: 10,
      total: newItems[0].length,
      totalPages: Math.ceil(newItems[0].length / 10),
      description,
    };
    dispatch(BatchesActions.confirmBatches(newSelectEnterprises));
    setShowDeleteUnifyConfirm(false);
    setPage(1);
  };

  const singleDelete = (item) => {
    setItemToDelete(item.id);
  };

  useEffect(() => {
    if (itemToDelete !== null) {
      setShowDeleteUnifyConfirm(true);
    }
  }, [itemToDelete]);

  const {
    handleChange,
    everyoneIsChecked,
    hasOneItemSelected,
    hasSelectedItems,
    listChecked: itemsChecked,
    selectedItems,
    toggleAll,
  } = useItems(selectEntreprises);
  const {
    params,
    orderBy,
    order,
    page,
    perPage,
    setPage,
    setPerPage,
    setOrderBy,
    setOrder,
  } = useParams({
    callback: fetchBatches,
    defaultOrderBy: "id",
  });

  return (
    <>
      <NavBar
        title={translate("batches")}
        description={description || ""}
        breadcrumb
        route={ROUTES.batches}
      >
        {hasSelectedItems && (
          <Span>{translate("batchesSelected", { count: selectedItems.length })}</Span>
        )}
        {hasSelectedItems && (
          <Button variant="outline" color="danger" handleClick={() => setShowDeleteConfirm(true)}>
            {translate("delete")}
          </Button>
        )}
        {!hasOneItemSelected && !hasSelectedItems && (
          <>
            <Button handleClick={() => handleClick()}>
              <span>{translate("issue")}</span>
            </Button>
          </>
        )}
      </NavBar>
      <TableFactoryWithSpinner
        requestStatus={requestPage}
        headers={[
          { slug: "cnpj", i18nKey: "cnpj" },
          { slug: "enterpriseName", i18nKey: "enterpriseName" },
          { slug: "plan", i18nKey: "plan" },
          { slug: "price", i18nKey: "lastInvoicePrice" },
        ]}
        allowedKeys={["cnpj", "enterpriseName", "plan", "price"]}
        items={itemsChecked}
        total={newTotal}
        totalPages={newTotalPages}
        orderBy={orderBy}
        order={order}
        page={page}
        perPage={perPage}
        setPage={setPage}
        setPerPage={setPerPage}
        setOrderBy={setOrderBy}
        setOrder={setOrder}
        everyoneIsChecked={everyoneIsChecked}
        handleChange={handleChange}
        toggleAll={toggleAll}
        urlDetails={(id) => LINKS.batchesConfirmEditEnterprise(id)}
        handleClickDelete={(item) => singleDelete(item)}
        handleClickEdit={(id) => LINKS.batchesConfirmEditEnterprise(id)}
      />
      <DeleteEnterpriseConfirmWithSuspense
        titleTranslateKey="deleteEnterprise"
        messageTranslateKey="deleteEnterpriseConfirm"
        loadComponent={showDeleteConfirm}
        showConfirm={showDeleteConfirm}
        onCancel={() => setShowDeleteConfirm(false)}
        onConfirm={enterpriseDelete}
      />
      <DeleteUnifyEnterpriseConfirmWithSuspense
        titleTranslateKey="deleteEnterprise"
        messageTranslateKey="deleteEnterpriseConfirm"
        loadComponent={showDeleteUnifyConfirm}
        showConfirm={showDeleteUnifyConfirm}
        onCancel={() => {
          setShowDeleteUnifyConfirm(false);
          setItemToDelete(null);
        }}
        onConfirm={enterpriseUnifyDelete}
      />
      <IssueInvoiceWithSuspense
        loadComponent={showModal}
        showModal={showModal}
        setShowModal={setShowModal}
        request={requestBatches}
        stageItem={stageItem}
        totalItems={dispatchItems.length}
        porcent={porcentInvoices}
        listInvoiceErrors={listInvoiceErrors}
        ConfirmError={finishThen}
        ConfirmListError={ConfirmListError}
      />
    </>
  );
};

export default BatchesListContainer;
