import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import pickBy from "lodash/pickBy";
import PropTypes from "prop-types";
import CardInfo from "components/core/CardInfo";
import { Container, Columns, ColumnFlex, RowFlex, Flex, Row } from "components/core/Grid";
import StatusDot, { StatusTypes } from "components/core/StatusDot";
import { MASK_TYPES, format, realToNumber, realWithNameToNumber } from "utils/masks";
import { formatDate } from "helpers";
import WithSpinner, { isPending, isRejected } from "components/core/WithSpinner";
import SubscriptionItemsList from "./Items";
import SubscriptionInvoicesList from "./Invoices";
import { Divider, Subtitle, Paragraph, Span } from "components/core/Typography";
import Button from "components/core/Button";
import Modal from "components/core/Modal";
import moment from "moment";
import InputForm from "components/core/InputForm";
import Checkbox from "components/core/Checkbox";
import { Spinner, SpinnerOverlay } from "components/core/WithSpinner/styled";
import { Form, InputHidden, InputGroup } from "components/core/Form";
import { ModalActions } from "components/core/Modal/styled";
import SelectForm from "components/core/SelectForm";
import { LINKS } from "utils/constants";
import Popup from "components/core/Popup";
import { IconQuestion } from "./styled";
import RadioButton from "components/core/RadioButton";
import { Chrevon } from "./icons";
import { RenderHeaders, Table, TableHeader, TableBody, TableItem } from "components/core/Table";

const SubscriptionItemsListWithSpinner = WithSpinner(SubscriptionItemsList);
const SubscriptionInvoicesListWithSpinner = WithSpinner(SubscriptionInvoicesList);

const SubscriptionDetails = ({
  subscription: {
    id,
    active,
    expiresAt,
    enterpriseName,
    userName,
    enterpriseTypeName,
    allowedPlans,
    cnpj,
    nextPaymentDate,
    price,
    planCode,
    planValues,
    email,
    lastBilling,
    billingDays,
  },
  subscriptionItems: { items },
  subscriptionInvoices,
  subscriptionInvoice,
  itemsParams,
  invoicesParams,
  requestStatusInvoice,
  requestStatusInvoices,
  requestStatusItems,
  translate,
  deleteSubscriptionItem,
  refreshItems,
  onSubmitItems,
  submitItemsRef,
  subscriptionItemsUseItems,
  refreshInvoices,
  subscriptionInvoicesUseInvoices,
  handleRemoveItems,
  getSubscriptionInvoice,
  billSubscription,
  showConfirmBillSubscription,
  setShowConfirmBillSubscription,
  processItemsByCycles,
  statusRequests,
  sendInvoiceMail,
  handleBillingsFrom,
  billingsFrom,
  simulatedSubscription,
  simulateSubscription,
  resetState,
  error,
}) => {
  const [addItem, setAddItem] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [isValidPaymentDate, setIsValidPaymentDate] = useState(false);
  const [newPaymentDate, setNewPaymentDate] = useState("");
  const [isMonthlyChecked, setIsMonthlyChecked] = useState(false);
  const [isYearlyChecked, setIsYearlyChecked] = useState(false);
  const [isBiennialChecked, setIsBiennialChecked] = useState(false);
  const [isGroupLoading, setIsGroupLoading] = useState(false);
  const [monthlyItems, setMonthlyItems] = useState([]);
  const [yearlyItems, setYearlyItems] = useState([]);
  const [biennialItems, setBiennialItems] = useState([]);
  const [activePlans, setActivePlans] = useState([]);
  const [actionPlanChanged, setActionPlanChanged] = useState(false);
  const [showConfirmDeleteItem, setShowConfirmDeleteItem] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [showIfDiscount, setShowIfDiscount] = useState(false);
  const [isValidPlans, setIsValidPlans] = useState(false);
  const [isValidForm, setIsValidForm] = useState(false);
  const [discountLabel, setDiscountLabel] = useState(null);
  const [selectedItemLocal, setSelectedItemLocal] = useState(null);
  const [mailParams, setMailParams] = useState(null);
  const [showModalMail, setShowModalMail] = useState(false);
  const [isDueDatePopupVisible, setDueDatePopupVisible] = useState(false);
  const [isLastBillingPopupVisible, setLastBillingPopupVisible] = useState(false);
  const [isNextBillingPopupVisible, setNextBillingPopupVisible] = useState(false);
  const [isNextPaymentPopupVisible, setNextPaymentPopupVisible] = useState(false);
  const [billingPeriodType, setBillingPeriodType] = useState("0");
  const [billingPeriod, setBillingPeriod] = useState(null);
  const [billingPeriodMonth, setBillingPeriodMonth] = useState(null);
  const [billingPeriodYear, setBillingPeriodYear] = useState(null);
  const [showBillingItems, setShowBillingItems] = useState(false);

  const itemPadding = "0.75rem 1rem";

  const headers = [
    { slug: "description", title: `${translate("description")}` },
    { slug: "price", title: `${translate("Value")}` },
    { slug: "quantity", title: `${translate("Quantity")}` },
    { slug: "total", title: `${translate("Total")}` },
  ];

  const defaultValues = {
    description: "",
    discountType: "V",
    discountValue: "",
    quantity: "",
    price: "",
    recurrent: true,
    type: "C",
    plan_mensal: "mensal",
  };

  const schemaTypeC = Yup.object().shape({
    id: Yup.number().nullable().optional(),
    type: Yup.string().required("Campo obrigatório"),
    recurrent: Yup.boolean().required("Campo obrigatório"),
    description: Yup.string().required("Campo obrigatório"),
    quantity: Yup.string().required("Campo obrigatório"),
    price: Yup.string().required("Campo obrigatório"),
    plan_anual: Yup.string().nullable().optional(),
    plan_bienal: Yup.string().nullable().optional(),
    plan_mensal: Yup.string().nullable().optional(),
  });
  const schemaTypeD = Yup.object().shape({
    id: Yup.number().nullable().optional(),
    type: Yup.string().required("Campo obrigatório"),
    discountType: Yup.string().required("Campo obrigatório"),
    description: Yup.string().required("Campo obrigatório"),
    discountValue: Yup.string().required("Campo obrigatório"),
    recurrent: Yup.boolean().required("Campo obrigatório"),
    plan_anual: Yup.string().nullable().optional(),
    plan_bienal: Yup.string().nullable().optional(),
    plan_mensal: Yup.string().nullable().optional(),
  });
  const { register: registerBilling, setValue: setValueBilling } = useForm({
    defaultValues: {
      billingPeriodMonth: "",
      billingPeriodYear: "",
    },
    mode: "onBlur",
  });
  const [schemaValidation, setSchemaValidation] = useState(schemaTypeC);
  const { register, handleSubmit, watch, errors, setValue, reset } = useForm({
    validationSchema: schemaValidation,
    defaultValues,
  });
  const typeWatch = watch("type");
  const discountTypeWatch = watch("discountType");
  const discountValueWatch = watch("discountValue");
  const quantityWatch = watch("quantity");
  const priceWatch = watch("price");
  const planAnnualWatch = watch("plan_anual");
  const planBiennialWatch = watch("plan_bienal");
  const planMontlhyWatch = watch("plan_mensal");
  const billingFromWatch = watch("billingFrom");
  const descriptionWatch = watch("description");
  const recurrentWatch = watch("recurrent");

  useEffect(() => {
    if (isRejected(statusRequests.SIMULATE_FINANCIAL_SUBSCRIPTION)) {
      setBillingPeriod(null);
      if (
        error?.message &&
        !error?.message.includes("Fatura já gerada para o período selecionado!")
      ) {
        setValueBilling("billingPeriodMonth", "");
        setValueBilling("billingPeriodYear", "");
        setBillingPeriodMonth(null);
        setBillingPeriodYear(null);
      }
    }
  }, [statusRequests.SIMULATE_FINANCIAL_SUBSCRIPTION, error]);

  useEffect(() => {
    if (billingPeriodMonth && billingPeriodYear) {
      resetBillingStates();
      setBillingPeriod(`${billingPeriodMonth}/${billingPeriodYear}`);
    }
  }, [billingPeriodMonth, billingPeriodYear, planCode]);

  useEffect(() => {
    const isValid =
      ["C", "D"].includes(typeWatch) &&
      billingsFrom?.includes(billingFromWatch) &&
      descriptionWatch?.length > 0 &&
      ["true", "false"].includes(recurrentWatch) &&
      (typeWatch == "C"
        ? priceWatch?.length > 0 && quantityWatch?.length > 0
        : ["V", "P"].includes(discountTypeWatch) && discountValueWatch?.length > 0);
    setIsValidForm(isValid && isValidPlans);
  }, [
    typeWatch,
    quantityWatch,
    priceWatch,
    billingFromWatch,
    descriptionWatch,
    isValidPlans,
    discountTypeWatch,
    discountValueWatch,
    recurrentWatch,
  ]);

  useEffect(() => {
    if (billingPeriod) {
      setNewPaymentDate("");
      handleSimulateSubscription();
    } else {
      setNewPaymentDate("");
      resetState({ stateName: "simulatedSubscription", newValue: null });
    }
  }, [billingPeriod]);

  useEffect(() => {
    if (simulatedSubscription) {
      if (moment(simulatedSubscription.dueDate).isBefore(moment())) {
        setNewPaymentDate(moment().add(1, "day").format("DD/MM/YYYY"));
        setIsValidPaymentDate(false);
      } else {
        const paymentDate = moment(simulatedSubscription.dueDate)
          .add(3, "hours")
          .format("DD/MM/YYYY");
        setNewPaymentDate(paymentDate);
        setIsValidPaymentDate(true);
      }
      if (simulatedSubscription.subscriptionItems?.length <= 10) {
        setShowBillingItems(true);
      }
    }
  }, [simulatedSubscription]);

  const handleSimulateSubscription = () => {
    simulateSubscription({
      id,
      billingPeriod,
    });
  };

  useEffect(() => {
    if (["0", "1"].includes(billingPeriodType)) {
      if (
        (!lastBilling || !lastBilling?.expiresAt || !lastBilling?.nextBillingPeriod) &&
        !expiresAt
      )
        return "";

      if (billingPeriodType === "0") {
        let expiresDate = lastBilling?.expiresAt ? lastBilling?.expiresAt : expiresAt;
        if (
          moment(expiresDate)
            .hours(0)
            .minutes(0)
            .seconds(0)
            .isBefore(moment().hours(23).minutes(59).seconds(59).milliseconds(999))
        )
          expiresDate = moment().add(1, "days").format("YYYY-MM-DD");
        const paymentDate = moment(expiresDate);
        const isValid = paymentDate.isAfter(
          moment().hours(23).minutes(59).seconds(59).milliseconds(999999),
        );
        setIsValidPaymentDate(isValid);
        setNewPaymentDate(paymentDate.format("DD/MM/YYYY"));
      }
      if (billingPeriodType === "1") {
      }
    }
  }, [billingPeriodType]);

  useEffect(() => {
    if (billingsFrom?.length > 0) {
      setValue("billingFrom", billingsFrom[0]);
    }
  }, [billingsFrom]);

  useEffect(() => {
    if (selectedItemLocal) {
      const formData = {
        ...selectedItemLocal,
        price: format(Number(selectedItemLocal.price).toFixed(2), MASK_TYPES.realWithName),
        discountValue: format(
          Number(selectedItemLocal.discountValue).toFixed(2),
          selectedItemLocal.discountType === "P" ? MASK_TYPES.percentage : MASK_TYPES.realWithName,
        ),
        [`plan_${selectedItemLocal.planCode}`]: selectedItemLocal.planCode,
      };
      reset(formData);
    }
  }, [selectedItemLocal]);

  const selectItem = (id) => {
    const item = items?.find((item) => item.id === id);
    setSelectedItem(item);
    setSelectedItemLocal(item);
  };

  const handleAddItem = () => {
    resetForm();
    handleBillingsFrom();
    selectItem(null);
    setAddItem(false);
    setShowForm(true);
  };

  const resetForm = () => {
    reset();
  };

  const handleEditItem = (id) => {
    handleBillingsFrom();
    selectItem(id);
    setShowForm(true);
  };

  const confirmDeleteItem = (id) => {
    selectItem(id);
    setShowConfirmDeleteItem(true);
  };

  useEffect(() => {
    if (addItem) {
      handleAddItem();
    }
  }, [addItem]);

  useEffect(() => {
    const isValid =
      planAnnualWatch || planBiennialWatch || planMontlhyWatch || selectedItemLocal?.planCode;
    setIsValidPlans(isValid?.length > 0);
  }, [planAnnualWatch, planBiennialWatch, planMontlhyWatch]);

  useEffect(() => {
    setShowIfDiscount(typeWatch === "D");
    if (typeWatch === "C") {
      setSchemaValidation(schemaTypeC);
    }
    if (typeWatch === "D") {
      setSchemaValidation(schemaTypeD);
    }
  }, [typeWatch]);

  useEffect(() => {
    setDiscountLabel(discountTypeWatch === "V" ? "Valor *" : "Percentual *");
    if (!selectedItemLocal) {
      setValue("discountValue", null);
      setValue("price", null);
      setValue("quantity", null);
    }
  }, [discountTypeWatch]);

  useEffect(() => {
    if (!selectedItemLocal) {
      setValue("discountValue", null);
      setValue("price", null);
      setValue("quantity", null);
    }
  }, [typeWatch]);

  useEffect(() => {
    if (quantityWatch && priceWatch) {
      setValue(
        "total",
        format(
          Number(quantityWatch * realWithNameToNumber(priceWatch)).toFixed(2),
          MASK_TYPES.realWithName,
        ),
      );
    }
  }, [quantityWatch, priceWatch]);

  useEffect(() => {
    const loading =
      isPending(statusRequests.GET_FINANCIAL_SUBSCRIPTION_ITEMS) ||
      isPending(statusRequests.GET_FINANCIAL_SUBSCRIPTION_ITEMS_BY_CYCLES) ||
      isPending(statusRequests.DELETE_FINANCIAL_SUBSCRIPTION_ITEM) ||
      isPending(statusRequests.SAVE_FINANCIAL_SUBSCRIPTION_ITEM);
    setIsGroupLoading(loading);
  }, [
    statusRequests.GET_FINANCIAL_SUBSCRIPTION_ITEMS,
    statusRequests.GET_FINANCIAL_SUBSCRIPTION_ITEMS_BY_CYCLES,
    statusRequests.SAVE_FINANCIAL_SUBSCRIPTION_ITEM,
    statusRequests.DELETE_FINANCIAL_SUBSCRIPTION_ITEM,
  ]);

  useEffect(() => {
    if (allowedPlans && items) {
      for (const cycle of allowedPlans) {
        const cycleItems = items?.filter((item) => item.planCode === cycle);
        if (cycle === "mensal") setMonthlyItems(cycleItems);
        if (cycle === "anual") setYearlyItems(cycleItems);
        if (cycle === "bienal") setBiennialItems(cycleItems);
      }
    }
  }, [items, allowedPlans]);

  useEffect(() => {
    if (allowedPlans) {
      setIsMonthlyChecked(allowedPlans.includes("mensal"));
      setIsYearlyChecked(allowedPlans.includes("anual"));
      setIsBiennialChecked(allowedPlans.includes("bienal"));
    }
  }, [allowedPlans]);

  useEffect(() => {
    setActivePlans([
      { code: "mensal", checked: isMonthlyChecked },
      { code: "anual", checked: isYearlyChecked },
      { code: "bienal", checked: isBiennialChecked },
    ]);
  }, [isMonthlyChecked, isYearlyChecked, isBiennialChecked]);

  useEffect(() => {
    if (actionPlanChanged) {
      processItemsByCycles(activePlans.filter((plan) => plan.checked).map((plan) => plan.code));
      setActionPlanChanged(false);
    }
  }, [activePlans]);

  const handleBillSubscription = () => {
    setShowConfirmBillSubscription(false);
    let newBillingPeriod = billingPeriod;
    let paymentDate = newPaymentDate;
    if (paymentDate.includes("/")) {
      const dateParts = paymentDate.split("/");
      paymentDate = moment(`${dateParts[2]}-${dateParts[1]}/${dateParts[0]}`);
    }
    if (newBillingPeriod && newBillingPeriod.includes("/")) {
      const dateParts = newBillingPeriod.split("/");
      newBillingPeriod = moment(`${dateParts[1]}/${dateParts[0]}`);
    }
    billSubscription({
      id,
      paymentDate,
      billingPeriod: newBillingPeriod,
      subscriptionPeriods: simulatedSubscription?.subscriptionPeriods,
    });
  };

  useEffect(() => {
    const paymentDate = moment(nextPaymentDate).hours(0).minutes(0).seconds(0).milliseconds(0);
    const today = moment(moment().hours(23).minutes(59).seconds(59).milliseconds(999999));
    setIsValidPaymentDate(paymentDate.isAfter(today));
  }, [nextPaymentDate]);

  const processItems = (cycle) => {
    setActionPlanChanged(true);
    if (cycle === "allowMonthly") {
      setIsMonthlyChecked(!isMonthlyChecked);
    }
    if (cycle === "allowYearly") {
      setIsYearlyChecked(!isYearlyChecked);
    }
    if (cycle === "allowBiennial") {
      setIsBiennialChecked(!isBiennialChecked);
    }
  };

  const isDisabled = (cycle) => cycle === planCode;

  const handleDeleteItem = () => {
    setShowConfirmDeleteItem(false);
    deleteSubscriptionItem(selectedItem.id);
  };

  const handleCancel = () => {
    setSelectedItem(null);
    setSelectedItemLocal(null);
    reset(defaultValues);
    setShowForm(false);
  };

  const onSubmit = (data) => {
    delete data.total;
    let formattedData = {};
    if (data.type === "C") {
      formattedData = pickBy({
        ...data,
        id: selectedItemLocal?.id,
        price: realWithNameToNumber(data.price),
        quantity: Number(data.quantity),
      });
    }
    if (data.type === "D") {
      formattedData = pickBy({
        ...data,
        id: selectedItemLocal?.id,
        discountValue:
          data.discountType === "V"
            ? realWithNameToNumber(data.discountValue)
            : realToNumber(data.discountValue),
        quantity: Number(data.quantity),
      });
    }
    formattedData.recurrent = data.recurrent;
    formattedData.cycles = [];
    if (data.plan_anual) formattedData.cycles.push(data.plan_anual);
    if (data.plan_bienal) formattedData.cycles.push(data.plan_bienal);
    if (data.plan_mensal) formattedData.cycles.push(data.plan_mensal);
    if (isValidForm) {
      onSubmitItems(formattedData);
      setShowForm(false);
    }
  };

  const handleMail = (invoice) => {
    const names = userName.split(" ");
    const name = names.length > 2 ? `${names[0]} ${names[names.length - 1]}` : names.join(" ");
    setMailParams({
      id: invoice.id,
      gatewayInvoiceId: invoice.gatewayInvoiceId,
      name: name.trim(),
      email,
      url: `${process.env.REACT_APP_URL}${LINKS.invoicePayment(invoice.gatewayInvoiceId)}`,
    });
    setShowModalMail(true);
  };

  const sendMail = (subject, message, greetings, emails) => {
    const params = {
      ...mailParams,
      subject,
      message,
      greetings,
    };
    if (emails) {
      let emailsArray = emails.split(",");
      if (emails.slice(-1) === ",") {
        emailsArray = emails.slice(0, -1).split(",");
      }
      params.email = emailsArray[0].trim();
      if (emailsArray.length > 1) {
        params.ccEmails = emailsArray
          .filter((email) => params?.email !== email.trim())
          .map((email) => email.trim());
      }
    } else {
      params.emails = mailParams?.email;
    }
    sendInvoiceMail(params);
  };

  const renderPlanFields = () => {
    return (
      <InputGroup paddingLeft={0} columns="3">
        {allowedPlans?.map((plan) => (
          <Checkbox
            key={plan}
            name={`plan_${plan}`}
            label={translate(plan)}
            value={plan}
            register={register}
            marginBottom={0}
          />
        ))}
      </InputGroup>
    );
  };

  const renderTitleWithPopup = (title, popupMsg, visibilityStatus, visibilityAction) => {
    return (
      <Popup
        component={
          <RowFlex verticalAlign="center" horizontalAlign="flex-start">
            {title}
            <IconQuestion
              fill="blue"
              onClick={() => visibilityAction(true)}
              style={{
                cursor: "pointer",
              }}
            />
          </RowFlex>
        }
        title={title}
        isPopupVisible={visibilityStatus}
        dismissPopupAction={() => visibilityAction(false)}
      >
        <Span>{popupMsg}</Span>
      </Popup>
    );
  };

  const formatBillingPeriod = (billingData) => {
    if (!billingData || !billingData?.billingPeriod) return "";
    return moment(billingData?.billingCompetence).format("MM/YYYY");
  };

  const formatNextBillingDate = (billingData) => {
    if (!lastBilling || !billingData || !billingData?.expiresAt || !billingData?.nextBillingPeriod)
      return "";
    const nextBillingDate = moment(lastBilling?.subscriptionEnd)
      .add(1, "months")
      .subtract(billingDays, "days")
      .format("DD/MM/YYYY");
    const nextBillingPeriod = moment(lastBilling?.subscriptionEnd)
      .add(1, "months")
      .format("MM/YYYY");
    return `${nextBillingDate} - ${nextBillingPeriod}`;
  };

  const formatNextPaymentDate = (onlyPeriod = false) => {
    if ((!lastBilling || !lastBilling?.expiresAt || !lastBilling?.nextBillingPeriod) && !expiresAt)
      return "";
    const expiresDate = lastBilling?.expiresAt ? lastBilling?.expiresAt : expiresAt;
    const expiresAtDate = moment(expiresDate).add(1, "day").format("DD");
    return moment(lastBilling?.nextBillingPeriod || expiresDate)
      .date(expiresAtDate)
      .format(onlyPeriod ? (planCode === "mensal" ? "MM/YYYY" : "YYYY") : "DD/MM/YYYY");
  };

  const popupDueDate = () => {
    return renderTitleWithPopup(
      translate("Subscription Due Date"),
      "Data de vencimento da assinatura refere-se à data do último pagamento adicionado o intervalo contratado do plano.",
      isDueDatePopupVisible,
      setDueDatePopupVisible,
    );
  };

  const popupLastBilling = () => {
    return renderTitleWithPopup(
      translate("Last Billing Period"),
      "Refere-se ao último período faturado e válido para a assinatura, sendo o mês ou ano do vencimento da fatura. Não compreende os faturamentos efetuados e cancelados posteriormente.",
      isLastBillingPopupVisible,
      setLastBillingPopupVisible,
    );
  };

  const popupNextBilling = () => {
    return renderTitleWithPopup(
      translate("Next Billing Date"),
      "Data do próximo faturamento automático, refere-se ao próximo ciclo de faturamento da assinatura subtraído dos dias de faturamento configurado na assinatura.",
      isNextBillingPopupVisible,
      setNextBillingPopupVisible,
    );
  };

  const popupNextPayment = () => {
    return renderTitleWithPopup(
      translate("Next Payment Date"),
      "Data de previsão do próximo pagamento, dia posterior ao vencimento da assinatura.",
      isNextPaymentPopupVisible,
      setNextPaymentPopupVisible,
    );
  };

  const handleCloseModalBillSubscription = () => {
    resetState({ stateName: "simulatedSubscription", newValue: null });
    setNewPaymentDate("");
    setBillingPeriodType("0");
    setBillingPeriod(null);
    setShowConfirmBillSubscription(false);
  };

  const toggleShowBillingItems = () => setShowBillingItems(!showBillingItems);

  const resetBillingStates = () => {
    resetState([
      { stateName: "error", newValue: null },
      {
        stateName: "SIMULATE_FINANCIAL_SUBSCRIPTION",
        isRequestState: true,
      },
    ]);
  };

  const getStyles = (type) => ({ color: type === "D" ? "red" : "black" });

  const getDiscountMask = (discountType) =>
    discountType === "P"
      ? MASK_TYPES.negativePercentageWithSymbol
      : MASK_TYPES.negativeRealWithName;

  return (
    <>
      <Container>
        <Columns columns="3">
          <CardInfo
            label={translate("Active") + "?"}
            info={
              <>
                <StatusDot type={active ? StatusTypes.SUCCESS : StatusTypes.ERROR} />
                {active ? "Sim" : "Não"}
              </>
            }
          />
          <CardInfo label={translate("emitter")} info={enterpriseName || userName} />
          <CardInfo label="CNPJ" info={cnpj ? format(cnpj, MASK_TYPES.cnpj) : ""} />
        </Columns>
        <Columns columns="3">
          <CardInfo
            label={translate("Value")}
            info={format(Number(price).toFixed(2), MASK_TYPES.realWithName)}
          />
          <CardInfo label={translate("Emitter Type")} info={enterpriseTypeName} />
          <CardInfo
            label={translate("Plans")}
            infoContainer={
              <InputGroup paddingLeft={0} columns="3">
                <Checkbox
                  key="allowMonthly"
                  name="allowMonthly"
                  label="Mensal"
                  handleChange={({ target: { name } }) => processItems(name)}
                  checked={isMonthlyChecked}
                  disabled={isDisabled("mensal")}
                  marginBottom={0}
                />
                <Checkbox
                  key="allowYearly"
                  name="allowYearly"
                  label="Anual"
                  handleChange={({ target: { name } }) => processItems(name)}
                  checked={isYearlyChecked}
                  disabled={isDisabled("anual")}
                  marginBottom={0}
                />
                <Checkbox
                  key="allowBiennial"
                  name="allowBiennial"
                  label="Bienal"
                  handleChange={({ target: { name } }) => processItems(name)}
                  checked={isBiennialChecked}
                  disabled={isDisabled("bienal")}
                  marginBottom={0}
                />
              </InputGroup>
            }
          />
        </Columns>
        <Columns columns="4">
          <CardInfo label={popupDueDate()} info={expiresAt && formatDate(expiresAt, 0, false)} />
          <CardInfo
            label={popupNextPayment()}
            info={nextPaymentDate && formatDate(nextPaymentDate, 0, false)}
          />
          <CardInfo
            label={popupLastBilling()}
            info={lastBilling && formatBillingPeriod(lastBilling)}
          />
          <CardInfo
            label={popupNextBilling()}
            info={lastBilling && formatNextBillingDate(lastBilling)}
          />
        </Columns>
        <Divider marginBottom={"1rem"} />
        {isGroupLoading && (
          <SpinnerOverlay>
            <ColumnFlex>
              <Spinner />
              <Span style={{ marginTop: "0.5rem" }}>Aguarde...</Span>
            </ColumnFlex>
          </SpinnerOverlay>
        )}
        {!isGroupLoading && (
          <>
            <RowFlex horizontalAlign="flex-end" marginBottom={0} marginTop={0}>
              <Button handleClick={handleAddItem} variant="outline">
                {translate("add")}
              </Button>
              <Button handleClick={() => refreshItems()}>{translate("Refresh")}</Button>
            </RowFlex>
            {isMonthlyChecked && (
              <>
                <Subtitle style={{ marginBottom: "0" }}>
                  {`${translate("Subscription Monthly Items")} ${
                    !isDisabled("mensal")
                      ? ` - Total: ${format(
                          Number(planValues?.mensal?.price).toFixed(2),
                          MASK_TYPES.realWithName,
                        )}`
                      : ""
                  }`}
                </Subtitle>
                <SubscriptionItemsListWithSpinner
                  items={monthlyItems}
                  requestStatus={requestStatusItems}
                  total={monthlyItems?.length}
                  totalPages={monthlyItems?.length}
                  page={itemsParams?.params?.page}
                  perPage={itemsParams?.params?.perPage}
                  params={{
                    setPerPage: itemsParams.setPerPage,
                    setPage: itemsParams.setPage,
                    setOrderBy: itemsParams.setOrderBy,
                    setOrder: itemsParams.setOrder,
                  }}
                  order={itemsParams?.params?.order}
                  orderBy={itemsParams?.params?.sort}
                  translate={translate}
                  toggleAll={subscriptionItemsUseItems?.toggleAll}
                  everyoneIsChecked={subscriptionItemsUseItems?.everyoneIsChecked}
                  handleChange={subscriptionItemsUseItems?.handleChange}
                  handleEditItem={handleEditItem}
                  confirmDeleteItem={confirmDeleteItem}
                  feedbackTextOnly={true}
                  view
                />
                <Divider />
              </>
            )}
            {isYearlyChecked && (
              <>
                <Subtitle style={{ marginBottom: "0" }}>
                  {`${translate("Subscription Yearly Items")} ${
                    !isDisabled("anual")
                      ? ` - Total: ${format(
                          Number(planValues?.anual?.price).toFixed(2),
                          MASK_TYPES.realWithName,
                        )}`
                      : ""
                  }`}
                </Subtitle>
                <SubscriptionItemsListWithSpinner
                  items={yearlyItems}
                  requestStatus={requestStatusItems}
                  total={yearlyItems?.length}
                  totalPages={yearlyItems?.length}
                  page={itemsParams?.params?.page}
                  perPage={itemsParams?.params?.perPage}
                  params={{
                    setPerPage: itemsParams.setPerPage,
                    setPage: itemsParams.setPage,
                    setOrderBy: itemsParams.setOrderBy,
                    setOrder: itemsParams.setOrder,
                  }}
                  order={itemsParams?.params?.order}
                  orderBy={itemsParams?.params?.sort}
                  translate={translate}
                  toggleAll={subscriptionItemsUseItems?.toggleAll}
                  everyoneIsChecked={subscriptionItemsUseItems?.everyoneIsChecked}
                  handleChange={subscriptionItemsUseItems?.handleChange}
                  handleEditItem={handleEditItem}
                  confirmDeleteItem={confirmDeleteItem}
                  feedbackTextOnly={true}
                  view
                />
                <Divider />
              </>
            )}
            {isBiennialChecked && (
              <>
                <Subtitle style={{ marginBottom: "0" }}>
                  {`${translate("Subscription Biennial Items")} ${
                    !isDisabled("bienal")
                      ? ` - Total: ${format(
                          Number(planValues?.bienal?.price).toFixed(2),
                          MASK_TYPES.realWithName,
                        )}`
                      : ""
                  }`}
                </Subtitle>
                <SubscriptionItemsListWithSpinner
                  items={biennialItems}
                  requestStatus={requestStatusItems}
                  total={biennialItems?.length}
                  totalPages={biennialItems?.length}
                  page={itemsParams?.params?.page}
                  perPage={itemsParams?.params?.perPage}
                  params={{
                    setPerPage: itemsParams.setPerPage,
                    setPage: itemsParams.setPage,
                    setOrderBy: itemsParams.setOrderBy,
                    setOrder: itemsParams.setOrder,
                  }}
                  order={itemsParams?.params?.order}
                  orderBy={itemsParams?.params?.sort}
                  translate={translate}
                  toggleAll={subscriptionItemsUseItems?.toggleAll}
                  everyoneIsChecked={subscriptionItemsUseItems?.everyoneIsChecked}
                  handleChange={subscriptionItemsUseItems?.handleChange}
                  handleEditItem={handleEditItem}
                  confirmDeleteItem={confirmDeleteItem}
                  feedbackTextOnly={true}
                  view
                />
                <Divider />
              </>
            )}
          </>
        )}
        <RowFlex horizontalAlign="space-between" marginBottom="1rem">
          <RowFlex horizontalAlign="flex-start" marginBottom="0">
            <Subtitle style={{ marginBottom: "0" }}>{translate("Subscription Invoices")}</Subtitle>
          </RowFlex>
          <RowFlex horizontalAlign="flex-end">
            <Button handleClick={() => refreshInvoices()}>{translate("Refresh")}</Button>
          </RowFlex>
        </RowFlex>
        <SubscriptionInvoicesListWithSpinner
          items={subscriptionInvoicesUseInvoices?.listChecked || subscriptionInvoices.items}
          requestStatus={requestStatusInvoices}
          total={subscriptionInvoices.total}
          totalPages={subscriptionInvoices.totalPages}
          page={invoicesParams?.params?.page}
          perPage={invoicesParams?.params?.perPage}
          params={{
            setPerPage: invoicesParams.setPerPage,
            setPage: invoicesParams.setPage,
            setOrderBy: invoicesParams.setOrderBy,
            setOrder: invoicesParams.setOrder,
          }}
          order={invoicesParams?.params?.order}
          orderBy={invoicesParams?.params?.sort}
          translate={translate}
          toggleAll={subscriptionInvoicesUseInvoices?.toggleAll}
          everyoneIsChecked={subscriptionInvoicesUseInvoices?.everyoneIsChecked}
          handleChange={subscriptionInvoicesUseInvoices?.handleChange}
          getSubscriptionInvoice={getSubscriptionInvoice}
          subscriptionInvoice={subscriptionInvoice}
          requestStatusInvoice={requestStatusInvoice}
          handleMail={handleMail}
          setShowModalMail={setShowModalMail}
          showModalMail={showModalMail}
          mailParams={mailParams}
          sendMail={sendMail}
          view
        />
      </Container>
      <Modal
        title={`Confirma a exclusão do item?`}
        confirmLabel="Sim"
        onConfirmModal={handleDeleteItem}
        showModal={showConfirmDeleteItem}
        showActions={true}
        hideConfirm={false}
        hideCancel={false}
        cancelLabel="Não"
      >
        <Flex verticalAlign="center" horizontalAlign="center">
          <Paragraph>{selectedItem?.description}</Paragraph>
        </Flex>
      </Modal>
      <Modal
        title={selectedItemLocal ? `Edição do item ${selectedItemLocal?.description}` : "Novo item"}
        onCloseModal={() => setShowForm(false)}
        showModal={showForm}
        textAlign="text-left"
        showActions={false}
      >
        <Form onSubmit={handleSubmit(onSubmit)}>
          {!selectedItemLocal && (
            <CardInfo label={translate("Apply to Plans")} infoContainer={renderPlanFields()} />
          )}
          <InputGroup paddingLeft={0} columns="3">
            <SelectForm
              register={register}
              name="type"
              label="Tipo do item"
              options={[
                { value: "C", text: "Cobrança" },
                { value: "D", text: "Desconto" },
              ]}
              disabled={selectedItemLocal ? true : false}
            />
            <>
              {!showIfDiscount && (
                <SelectForm
                  register={register}
                  name="recurrent"
                  label="Recorrente?"
                  options={[
                    { value: true, text: "Sim" },
                    { value: false, text: "Não" },
                  ]}
                />
              )}
              {showIfDiscount && (
                <SelectForm
                  register={register}
                  name="discountType"
                  label="Tipo do desconto"
                  options={[
                    { value: "P", text: "Percentual" },
                    { value: "V", text: "Valor" },
                  ]}
                />
              )}
              <SelectForm
                register={register}
                name="billingFrom"
                label="Aplicar em/a partir de"
                options={billingsFrom?.map((nb) => ({
                  value: nb,
                  text: nb,
                }))}
                isLoading={!billingsFrom?.length}
              />
            </>
          </InputGroup>
          <InputGroup paddingLeft={0}>
            <InputForm
              register={register}
              type="text"
              name="description"
              label={`${translate("description")} *`}
              placeholder={translate("typeDescription")}
              errorMsg={errors.description?.message}
            />
          </InputGroup>
          <InputGroup paddingLeft={0} columns={2}>
            {showIfDiscount && (
              <>
                <InputForm
                  register={register}
                  type="text"
                  name="discountValue"
                  label={`${discountLabel}`}
                  errorMsg={errors.discountValue?.message}
                  mask={discountTypeWatch === "P" ? MASK_TYPES.percentage : MASK_TYPES.realWithName}
                />
                <SelectForm
                  register={register}
                  name="recurrent"
                  label="Recorrente?"
                  options={[
                    { value: true, text: "Sim" },
                    { value: false, text: "Não" },
                  ]}
                />
              </>
            )}
          </InputGroup>
          {!showIfDiscount && (
            <InputGroup paddingLeft={0} columns={3}>
              <InputForm
                register={register}
                type="number"
                name="quantity"
                step="1"
                min="1"
                label={`${translate("Quantity")} *`}
                errorMsg={errors?.quantity?.message}
              />
              <InputForm
                register={register}
                type="text"
                name="price"
                label="Valor *"
                errorMsg={errors.price?.message}
                mask={MASK_TYPES.realWithName}
              />
              <InputForm
                register={register}
                type="text"
                name="total"
                label="Total"
                disabled={true}
                mask={MASK_TYPES.realWithName}
              />
            </InputGroup>
          )}
          {selectedItemLocal && (
            <InputHidden ref={register} type="text" name={`plan_${selectedItemLocal.planCode}`} />
          )}
          <InputHidden ref={submitItemsRef} type="submit" value="Submit" />
          <ModalActions>
            <Button variant="outline" handleClick={handleCancel}>
              {translate("cancel")}
            </Button>
            <Button type="submit" disabled={!isValidForm}>
              {translate("save")}
            </Button>
          </ModalActions>
        </Form>
      </Modal>
      <Modal
        title={`Confirma a exclusão dos itens?`}
        confirmLabel="Sim"
        onConfirmModal={handleRemoveItems}
        showModal={showConfirmDelete}
        showActions={true}
        hideConfirm={false}
        hideCancel={false}
        cancelLabel="Não"
      >
        <Flex verticalAlign="center" horizontalAlign="center">
          {subscriptionItemsUseItems?.selectedItems?.map((item) => (
            <Paragraph key={`subItem_${item.id}`}>{item?.description}</Paragraph>
          ))}
        </Flex>
      </Modal>
      <Modal
        title={`Faturamento da assinatura #${id}`}
        confirmLabel={
          <>
            {isPending(statusRequests.SIMULATE_FINANCIAL_SUBSCRIPTION) &&
              isPending(statusRequests.BILL_SUBSCRIPTION) && (
                <Spinner width="2.5rem" height="2.5rem" marginRight="1rem" />
              )}
            Faturar
          </>
        }
        onConfirmModal={handleBillSubscription}
        showModal={showConfirmBillSubscription}
        onCloseModal={() => handleCloseModalBillSubscription()}
        showActions={true}
        hideConfirm={false}
        hideCancel={false}
        cancelLabel="Cancelar"
        width="85rem"
        disableConfirm={
          !billingPeriod ||
          isPending(statusRequests.SIMULATE_FINANCIAL_SUBSCRIPTION) ||
          isPending(statusRequests.BILL_SUBSCRIPTION)
        }
      >
        {isPending(statusRequests.BILL_SUBSCRIPTION) && (
          <ColumnFlex>
            <Spinner />
            <Span style={{ marginTop: "0.5rem" }}>Carregando parâmetros</Span>
          </ColumnFlex>
        )}

        {!isPending(statusRequests.BILL_SUBSCRIPTION) && (
          <Form>
            <Span>Informe o período para faturamento</Span>
            <InputGroup horizontalPadding={"10rem"} columns={2}>
              <SelectForm
                register={registerBilling}
                name="billingPeriodMonth"
                id="billingPeriodMonth"
                label="Selecione o mês"
                options={[
                  { value: "", text: "" },
                  { value: "01", text: "Janeiro" },
                  { value: "02", text: "Fevereiro" },
                  { value: "03", text: "Março" },
                  { value: "04", text: "Abril" },
                  { value: "05", text: "Maio" },
                  { value: "06", text: "Junho" },
                  { value: "07", text: "Julho" },
                  { value: "08", text: "Agosto" },
                  { value: "09", text: "Setembro" },
                  { value: "10", text: "Outubro" },
                  { value: "11", text: "Novembro" },
                  { value: "12", text: "Dezembro" },
                ]}
                isLoading={isPending(statusRequests.SIMULATE_FINANCIAL_SUBSCRIPTION)}
                handleChange={({ target: { value } }) => setBillingPeriodMonth(value)}
                marginBottom={0}
              />
              <SelectForm
                register={registerBilling}
                name="billingPeriodYear"
                id="billingPeriodYear"
                label="Selecione o ano"
                options={[
                  { value: "", text: "" },
                  { value: "2024", text: "2024" },
                  { value: "2025", text: "2025" },
                  { value: "2026", text: "2026" },
                  { value: "2027", text: "2027" },
                  { value: "2028", text: "2028" },
                  { value: "2029", text: "2029" },
                  { value: "2030", text: "2030" },
                ]}
                handleChange={({ target: { value } }) => setBillingPeriodYear(value)}
                marginBottom={0}
                isLoading={isPending(statusRequests.SIMULATE_FINANCIAL_SUBSCRIPTION)}
              />
            </InputGroup>
            {simulatedSubscription && simulatedSubscription.finalPrice < 1 && (
              <Span style={{ color: "red" }}>
                Não há items a serem faturados para essa competência.
              </Span>
            )}
            {isRejected(statusRequests.SIMULATE_FINANCIAL_SUBSCRIPTION) && error && (
              <Span style={{ color: "red" }}>
                {error?.message || "Erro ao simular faturamento da assinatura."}
              </Span>
            )}
            {simulatedSubscription && simulatedSubscription.finalPrice >= 1 && (
              <>
                <InputGroup columns={3} paddingLeft={0}>
                  <InputForm
                    type="text"
                    id="subscriptionPrice"
                    name="subscriptionPrice"
                    label={translate("Valor da fatura")}
                    value={format(
                      simulatedSubscription?.finalPrice.toFixed(2) || "0",
                      MASK_TYPES.realWithName,
                    )}
                    mask={MASK_TYPES.realWithName}
                    readOnly={true}
                  />
                  <InputForm
                    type="text"
                    id="newPaymentDate"
                    name="newPaymentDate"
                    label={translate("Vencimento da fatura")}
                    mask={MASK_TYPES.date}
                    value={newPaymentDate}
                    onChange={(e) => setNewPaymentDate(e.target.value)}
                    disabled={isPending(statusRequests.SIMULATE_FINANCIAL_SUBSCRIPTION)}
                  />
                  <InputForm
                    type="text"
                    id="subscriptionPeriod"
                    name="subscriptionPeriod"
                    label={translate("Cobertura da Assinatura")}
                    value={simulatedSubscription?.subscriptionPeriod}
                    readOnly={true}
                  />
                </InputGroup>
                {simulatedSubscription?.subscriptionItems && (
                  <>
                    <Button
                      variant={"text"}
                      handleClick={toggleShowBillingItems}
                      title={showBillingItems ? "Ocultar itens" : "Exibir itens"}
                    >
                      Items da fatura <Chrevon />
                    </Button>
                    {showBillingItems && (
                      <Container
                        horizontalAlign="center"
                        horizontalPadding={0}
                        verticalPadding={0}
                        marginBottom={"1rem"}
                      >
                        <Table>
                          <TableHeader>
                            <RenderHeaders headers={headers} />
                          </TableHeader>
                          <TableBody>
                            {simulatedSubscription?.subscriptionItems?.map(
                              ({
                                id,
                                description,
                                quantity,
                                price,
                                type,
                                discountType,
                                discountValue,
                              }) => (
                                <tr key={id}>
                                  <TableItem padding={itemPadding}>{description}</TableItem>
                                  <TableItem padding={itemPadding}>
                                    <Span style={getStyles(type)}>
                                      {format(
                                        Number(type === "D" ? discountValue : price).toFixed(2),
                                        type === "D"
                                          ? getDiscountMask(discountType)
                                          : MASK_TYPES.realWithName,
                                      )}
                                    </Span>
                                  </TableItem>
                                  <TableItem padding={itemPadding}>{quantity}</TableItem>
                                  <TableItem padding={itemPadding}>
                                    <Span style={getStyles(type)}>
                                      {format(
                                        Number(
                                          type === "D" ? discountValue : price * quantity,
                                        ).toFixed(2),
                                        type === "D"
                                          ? getDiscountMask(discountType)
                                          : MASK_TYPES.realWithName,
                                      )}
                                    </Span>
                                  </TableItem>
                                </tr>
                              ),
                            )}
                          </TableBody>
                        </Table>
                      </Container>
                    )}
                  </>
                )}
              </>
            )}
          </Form>
        )}

        {/* {!isPending(statusRequests.GET_FINANCIAL_SUBSCRIPTION_AVAILABLE_BILLING_PERIODS) && (
          <Flex verticalAlign="center" horizontalAlign="center">
            {availableBillingPeriods?.length > 1 && (
              <RowFlex horizontalAlign="space-between">
                <RadioButton
                  name="billingPeriod"
                  label="Próximo Período"
                  id="currentPeriod"
                  value="0"
                  checked={billingPeriodType === "0"}
                  onClick={(el) => setBillingPeriodType(el.target.value)}
                  onChange={(el) => setBillingPeriodType(el.target.value)}
                  marginBottom={0}
                />
                <RadioButton
                  name="billingPeriod"
                  label="Períodos Anteriores"
                  id="selectedPeriod"
                  value="1"
                  checked={billingPeriodType === "1"}
                  onClick={(el) => setBillingPeriodType(el.target.value)}
                  onChange={(el) => setBillingPeriodType(el.target.value)}
                  marginBottom={0}
                />
              </RowFlex>
            )}
            {billingPeriodType === "0" && (
              <Span style={{ marginBottom: "1.5rem" }}>
                {`Período de Faturamento: `} <strong>{formatNextPaymentDate(true)}</strong>
              </Span>
            )}
            {billingPeriodType === "1" && (
              <SelectForm
                name="billingPeriod"
                id="billingPeriod"
                label="Selecione o período de faturamento"
                options={availableBillingPeriods?.map((nb) => ({
                  value: nb,
                  text: nb,
                }))}
                isLoading={
                  !availableBillingPeriods?.length ||
                  isPending(statusRequests.SIMULATE_FINANCIAL_SUBSCRIPTION)
                }
                handleChange={(el) => setBillingPeriod(el.target.value)}
                marginBottom={0}
              />
            )}
            {billingPeriodType === "1" && simulatedSubscription && (
              <InputGroup columns={2} paddingLeft={0}>
                <InputForm
                  type="text"
                  id="subscriptionPrice"
                  name="subscriptionPrice"
                  label={translate("Valor da fatura")}
                  value={format(
                    simulatedSubscription?.finalPrice.toFixed(2) || "0",
                    MASK_TYPES.realWithName,
                  )}
                  mask={MASK_TYPES.realWithName}
                  readOnly={true}
                />
                <InputForm
                  type="text"
                  id="newPaymentDate"
                  name="newPaymentDate"
                  label={translate("Vencimento da fatura")}
                  mask={MASK_TYPES.date}
                  value={newPaymentDate}
                  onChange={(e) => setNewPaymentDate(e.target.value)}
                  disabled={isPending(statusRequests.SIMULATE_FINANCIAL_SUBSCRIPTION)}
                />
              </InputGroup>
            )}
            {isValidPaymentDate && billingPeriodType === "0" && (
              <Span>
                {translate("O vencimento da fatura será em ")}
                <strong>{formatNextPaymentDate()}</strong>
              </Span>
            )}
            {!isValidPaymentDate && billingPeriodType === "0" && (
              <>
                <InputForm
                  type="text"
                  id="newPaymentDate"
                  name="newPaymentDate"
                  label={translate("Informe a data de vencimento da fatura")}
                  mask={MASK_TYPES.date}
                  value={newPaymentDate}
                  onChange={(e) => setNewPaymentDate(e.target.value)}
                />
              </>
            )}
          </Flex>
        )} */}
      </Modal>
    </>
  );
};

SubscriptionDetails.propTypes = {
  subscription: PropTypes.object.isRequired,
  subscriptionItems: PropTypes.object.isRequired,
};

export default SubscriptionDetails;
