import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import InputForm from "components/core/InputForm";
import SelectForm from "components/core/SelectForm";
import { Subtitle, TertiaryTitle } from "components/core/Typography";
import { Columns, Container } from "components/core/Grid";
import { Form, InputHidden, InputGroup } from "components/core/Form";
import { MASK_TYPES } from "utils/masks";
import { formatNumberToReal } from "helpers/format";
import CardInfo from "components/core/CardInfo";
import { parseMoneyToFloat } from "helpers/sanitize";

const EditMEI = ({ onSubmit, defaultValues, submitRef }) => {
  const { t: translate } = useTranslation();
  const [maxBilling, setMaxBilling] = useState();
  const [annualBilling, setAnnualBilling] = useState();
  const [optionsAllowInstallments, setOptionsAllowInstallments] = useState();
  const [optionsApplyInterest, setOptionsApplyInterest] = useState();
  const [optionsMaxInstallments, setOptionsMaxInstallments] = useState();
  const { register, handleSubmit, errors, watch } = useForm({
    defaultValues,
    submitFocusError: true,
  });
  const watchMaximumMonthBilling = watch("meiMaximumMonthBilling");
  const watchMinInstallmentAmount = watch("minInstallmentAmount");
  const watchTaxCalc = watch("meiTaxCalc");
  const watchMeiOpeningTax = watch("meiOpeningTax");

  useEffect(() => {
    setMaxBilling(
      formatNumberToReal(
        parseMoneyToFloat(watchMaximumMonthBilling) *
          (parseFloat(watchTaxCalc.replace(",", ".")) / 100),
      ),
    );
  }, [watchTaxCalc, watchMaximumMonthBilling]);

  useEffect(() => {
    setAnnualBilling(formatNumberToReal(parseMoneyToFloat(watchMaximumMonthBilling) * 12));
  }, [watchMaximumMonthBilling]);

  useEffect(() => {
    const options = [
      { text: translate("Yes"), value: true, selected: defaultValues.allowInstallments === true },
      { text: translate("No"), value: false, selected: defaultValues.allowInstallments === false },
    ];
    setOptionsAllowInstallments(options);
    const optionsInterest = [
      { text: translate("Yes"), value: true, selected: defaultValues.applyInterest === true },
      { text: translate("No"), value: false, selected: defaultValues.applyInterest === false },
    ];
    setOptionsApplyInterest(optionsInterest);
  }, [defaultValues]);

  useEffect(() => {
    const newMinInstallmentAmount = parseMoneyToFloat(watchMinInstallmentAmount);
    const meiOpeningTax = parseMoneyToFloat(watchMeiOpeningTax);
    let options = [];
    if (!Number.isNaN(newMinInstallmentAmount) && !Number.isNaN(meiOpeningTax)) {
      for (let i = 1; i <= 12; i++) {
        const price = meiOpeningTax / i;
        if (price < newMinInstallmentAmount) break;
        options.push({ text: i, value: i });
      }
      if (options.length === 0) {
        options.push({ text: 1, value: 1, selected: true });
      } else {
        options = options.map((option) => {
          option.selected = false;
          if (option.value === options.length) {
            option.selected = true;
          }
          return option;
        });
      }
    }
    setOptionsMaxInstallments(options);
  }, [watchMinInstallmentAmount, watchMeiOpeningTax]);

  return (
    <Container maxWidth="md">
      <Subtitle>Editar MEI</Subtitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputGroup paddingLeft="0" columns="2">
          <InputForm
            register={register}
            type="text"
            name="meiOpeningTax"
            label="taxa de abertura de empresa"
            mask={MASK_TYPES.realWithName}
            errorMsg={errors.meiOpeningTax?.message}
          />
          <SelectForm
            register={register}
            name="allowInstallments"
            label="Parcelamento?"
            options={optionsAllowInstallments}
            errorMsg={errors.allowInstallments?.message}
          />
        </InputGroup>
        <InputGroup paddingLeft="0" columns="3">
          <InputForm
            register={register}
            type="text"
            name="minInstallmentAmount"
            label="Valor Mínimo Parcela"
            errorMsg={errors.minInstallmentAmount?.message}
            mask={MASK_TYPES.realWithName}
          />
          <SelectForm
            register={register}
            name="maxInstallments"
            label="Nº de parcelas"
            options={optionsMaxInstallments}
            errorMsg={errors.maxInstallments?.message}
          />
          <SelectForm
            register={register}
            name="applyInterest"
            label="Aplicar Juros?"
            options={optionsApplyInterest}
            errorMsg={errors.applyInterest?.message}
          />
        </InputGroup>
        <TertiaryTitle>Faixa Única</TertiaryTitle>
        <InputGroup paddingLeft="0" columns="2">
          <InputForm
            register={register}
            type="text"
            name="meiMaximumMonthBilling"
            label="Faturamento Máximo (MENSAL)"
            mask={MASK_TYPES.realWithName}
            errorMsg={errors.meiMaximumMonthBilling?.message}
          />
          <InputForm
            register={register}
            type="text"
            name="meiTaxCalc"
            label="Valor para cálculo de planos"
            errorMsg={errors.meiTaxCalc?.message}
            mask={MASK_TYPES.numberWithComma}
            content="%"
          />
        </InputGroup>
        <InputGroup paddingLeft="0" columns="2">
          <InputForm
            register={register}
            type="text"
            name="meiMinimumCalcPrice"
            label="Mensalidade Mínima"
            errorMsg={errors.meiMinimumCalcPrice?.message}
            mask={MASK_TYPES.realWithName}
          />
          <InputForm
            register={register}
            type="text"
            name="meiAnnualPrice"
            label="Valor Anual"
            errorMsg={errors.meiAnnualPrice?.message}
            mask={MASK_TYPES.realWithName}
          />
        </InputGroup>
        <InputGroup paddingLeft="0" columns="2">
          <InputForm
            register={register}
            type="text"
            name="meiBiennialPrice"
            label="Valor Bienal"
            errorMsg={errors.meiBiennialPrice?.message}
            mask={MASK_TYPES.realWithName}
          />
        </InputGroup>
        <InputHidden ref={submitRef} type="submit" value="Submit" />
      </Form>
      <InputGroup paddingLeft="0" columns="2">
        <CardInfo label="Mensalidade Máxima" info={maxBilling} />
        <CardInfo label="Faturamento Anual" info={annualBilling} />
      </InputGroup>
    </Container>
  );
};

EditMEI.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  submitRef: PropTypes.object.isRequired,
  defaultValues: PropTypes.object.isRequired,
};

export default EditMEI;
