import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Subtitle } from "components/core/Typography";
import { Flex, Row, Container } from "components/core/Grid";
import Checkbox from "components/core/Checkbox";
import { ErrorMsg } from "components/core/InputForm/styled";
import Button from "components/core/Button";
import { Trans } from "react-i18next";
import * as S from "./styled";

export const InvoiceTypes = ({
  onBack,
  setStep,
  setInvoiceTypes,
  isValid,
  issuacesMessage,
  type,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [validate, setValidate] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");

  const invoiceTypes = [
    {
      id: 1,
      name: "NFS-e",
      description: "Nota Fiscal de Serviço",
    },
    {
      id: 2,
      name: "NF-e",
      description: "Nota Fiscal de Produto",
    },
    {
      id: 3,
      name: "NFC-e",
      description: "Nota Fiscal de Consumidor",
    },
  ];

  useEffect(() => {
    if (isValid === 1) setValidate(1);
    else if (isValid === 2) setValidate(2);
    else if (isValid === 3) setValidate(3);
    else setValidate(0);
  }, [isValid]);

  useEffect(() => {
    if (issuacesMessage) setErrorMessage(issuacesMessage);
    else setErrorMessage("");
  }, [issuacesMessage]);

  const handleChange = (item) => {
    const itemSelectedIndex = selectedItems.findIndex(
      (selectedItem) => selectedItem.id === item.id,
    );
    const newSelectedItems =
      itemSelectedIndex === -1
        ? [...selectedItems, item]
        : selectedItems.filter((_, index) => itemSelectedIndex !== index);

    setSelectedItems(newSelectedItems);
  };

  const isChecked = (value) =>
    selectedItems.some((selectedItem) => Number(selectedItem.id) === Number(value));

  const handleContinueClick = () => {
    const invoiceTypesToParams = selectedItems.map(({ id }) => id);
    setInvoiceTypes(invoiceTypesToParams);
    window.dataLayer.push({
      event: "EtapaTiposNotas",
    });
  };

  useEffect(() => {
    if (validate === 2) {
      setStep((oldStep) => oldStep + 1);
    }
  }, [validate]);

  useEffect(() => {
    if (type === 4) {
      const invoiceTypesToParams = [1, 2];
      setInvoiceTypes(invoiceTypesToParams);
      window.dataLayer.push({
        event: "EtapaTiposNotas",
      });
    }
  }, [setInvoiceTypes]);

  return (
    <S.RegisterContainer>
      <Flex verticalAlign="center" horizontalAlign="center">
        <Container maxWidth="26rem" verticalPadding="0" horizontalPadding="0" margin="0 0 2.4rem 0">
          <S.StatusBar porcent="76%" />
        </Container>
        <Subtitle mb="64px">Quais tipos de notas você deseja emitir?</Subtitle>
        <Flex verticalAlign="center" horizontalAlign="flex-start" marginBottom="4rem">
          {invoiceTypes?.map((item) => (
            <Checkbox
              key={item.name}
              name={`${item.name}`}
              label={item.description}
              value={item.id}
              handleChange={() => handleChange(item)}
              checked={isChecked(item.id)}
            />
          ))}
          {validate === 1 && <S.SpinnerSmall />}
          {errorMessage && validate === 3 && (
            <ErrorMsg>
              <Trans i18nKey={errorMessage || ""} />
            </ErrorMsg>
          )}
        </Flex>
        <Row horizontalAlign="center">
          <Button handleClick={onBack} variant="outline">
            Voltar
          </Button>
          <Button handleClick={() => handleContinueClick()}>Continuar</Button>
        </Row>
      </Flex>
    </S.RegisterContainer>
  );
};

InvoiceTypes.propTypes = {
  onBack: PropTypes.func.isRequired,
  setInvoiceTypes: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  isValid: PropTypes.number.isRequired,
  issuacesMessage: PropTypes.string,
  type: PropTypes.number,
};

InvoiceTypes.defaultProps = {
  issuacesMessage: "",
};

export default InvoiceTypes;
