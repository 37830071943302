import React, { Suspense } from "react";
import SuspenseFallback from "components/core/SuspenseFallback";

const WithSuspense =
  (WrappedComponent) =>
  ({ loadComponent, ...otherProps }) => {
    if (!loadComponent) return null;

    return (
      <Suspense fallback={<SuspenseFallback />}>
        <WrappedComponent {...otherProps} />
      </Suspense>
    );
  };

export default WithSuspense;
